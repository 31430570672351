import stelace from 'hc-core/composables/stelace'

// Count companies
export async function countCompanies ({ commit, rootGetters }, { validated = true, active = true } = {}) {
  const companies = await stelace.assets.list({
    assetTypeId: rootGetters['common/companyTypeId'],
    nbResultsPerPage: 1,
    validated: validated,
    active: active
  })
  commit('search/setCompaniesCount', { companiesCount: companies.paginationMeta.nbResults }, { root: true })
  return companies
}

// Count offers
export async function countOffers ({ commit, rootGetters }, { validated = true, active = true } = {}) {
  const offers = await stelace.assets.list({
    assetTypeId: rootGetters['common/offerTypeId'],
    categoryId: rootGetters['common/offerCategoriesIds'],
    nbResultsPerPage: 1,
    validated: validated,
    active: active
  })
  commit('search/setOffersCount', { offersCount: offers.paginationMeta.nbResults }, { root: true })
  return offers
}
