import { date } from 'quasar'
import { debounce } from 'lodash'
import stelace from 'hc-core/composables/stelace'

const maxNetworkResourceDelay = 5000 // calls debounced up to this value
const expirationDuration = { minutes: -30 }

export async function initApp ({ dispatch }) {
  await dispatch('common/fetchConfig', { forceRefresh: true }, { root: true })
}

export async function fetchCategories ({ commit }, { forceRefresh = false } = {}) {
  const categories = await stelace.categories.list()
  commit('common/setCategoriesById', { categories }, { root: true })
  return categories
}

export async function fetchCustomAttributes ({ commit }, { forceRefresh = false } = {}) {
  const customAttributes = await stelace.customAttributes.list({ nbResultsPerPage: 100 })
  commit('common/setCustomAttributes', { customAttributes }, { root: true })
  return customAttributes
}

export const fetchConfig = debounce(async function ({ state, commit }, { forceRefresh = false } = {}) {
  const shouldFetchResults = forceRefresh || isPastExpirationDate(state.configLastFetchedDate)

  if (!shouldFetchResults) return state.config

  const config = await stelace.config.read()

  commit('common/setConfig', { config }, { root: true })

  return config
}, maxNetworkResourceDelay, { leading: true, trailing: false })

// Please refer to quasar date docs for timeObject details
function isPastExpirationDate (iso, timeObject = expirationDuration) {
  return !iso || iso < date.addToDate(new Date(), timeObject).toISOString()
}

export async function sendCustomEvent (context, { type, objectId, emitterId = 'happycab-v3', metadata }) {
  await stelace.events.create({
    type,
    objectId,
    emitterId,
    metadata
  })
}
