import { keyBy } from 'lodash'

export function setConfig (state, { config }) {
  state.config = config
  state.configLastFetchedDate = new Date().toISOString()
}

export function setCategoriesById (state, { categories }) {
  state.categoriesById = keyBy(categories, 'id')
}

export function setCustomAttributes (state, { customAttributes }) {
  state.customAttributesById = keyBy(customAttributes, 'id')
}
