import { store } from 'quasar/wrappers'
import { createStore } from 'vuex'

import asset from './asset'
import common from './common'
import content from './content'
import search from './search'

/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Store instance.
 */

export default store(function (/* { ssrContext } */) {
  const Store = createStore({
    modules: {
      asset,
      common,
      content,
      search
    }
  })

  return Store
})
