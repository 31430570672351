
const routes = [
  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { name: 'blog', path: '', component: () => import('pages/Blog/Blog.vue'), meta: { seoEntryName: 'home' } },
      { name: 'stories', path: 'stories', component: () => import('pages/Stories.vue') },
      { name: 'article', path: ':slug', component: () => import('pages/Blog/Article.vue'), meta: { dynamicSEO: true } },
    ]
  },

  // Always leave this as last one,
  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { name: 'redirect', path: 'redirect', component: () => import('pages/Redirect') },
    ]
  },
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/Error404.vue')
  }
]

export default routes
