import stelace from 'hc-core/composables/stelace'

// ##### Generic calls #####

export async function create (context, { asset }) {
  return await stelace.assets.create(asset)
}

export async function list (context, params) {
  return await stelace.assets.list(params)
}
