import stelace from 'hc-core/composables/stelace'

// ##### Generic calls #####

export async function list ({ commit }, { page = 1, nbResultsPerPage = 10, orderBy = 'createdDate', order = 'desc', collection = undefined, locale = 'fr', metadata = undefined, getResults = true }) {
  const arr = await stelace.forward.get('/entries/advanced', {
    page,
    nbResultsPerPage,
    orderBy,
    order,
    locale,
    metadata,
    collection,
  })
  commit('content/setEntries', { key: collection, arr }, { root: true })
  return getResults ? arr.results : arr
}

// ##### Specific calls #####

export async function fetchBlogContent ({ state, commit }, { locale, nbResultsPerPage = 6, page = 1, categories }) {
  locale = locale || state.defaultLocale
  const metadata = { active: true }
  if (categories && categories !== 'all') metadata.categories = categories
  const blogList = await stelace.forward.get('/entries/advanced', { collection: 'blog-vdc', locale, nbResultsPerPage, page, metadata, customOrderConfig: { orderBy: 'publishedDate', order: 'desc', nulls: 'last' } })
  if (process.env.CLIENT) {
    commit('content/setBlogList', blogList.results, { root: true })
    commit('content/setBlogPagination', {
      nbPages: blogList.nbPages,
      nbResultsPerPage: blogList.nbResultsPerPage,
      page: blogList.page
    }, { root: true })
    commit('content/setBlogCategory', categories, { root: true })
  }
  return blogList.results
}

export async function fetchArticle ({ state, commit }, { locale, slug, relatedArticle = false }) {
  locale = locale || state.defaultLocale
  const articles = await stelace.entries.list({ collection: 'blog-vdc,blog', locale, name: slug })
  if (!relatedArticle) commit('content/setArticle', { article: articles[0] }, { root: true })
  else commit('content/setRelatedArticle', { article: articles[0] }, { root: true })
  return articles[0]
}

export async function fetchi18nContent ({ commit }, { locale }) {
  commit('content/setLocale', { locale }, { root: true })
  await import(/* webpackChunkName: 'i18n-stl-[request]' */`../../../../../lib/i18n/build/${locale}.json`).then(translations => {
    commit('content/seti18nContent', { content: translations.default }, { root: true })
  })
}

export async function fetchSeo ({ state, commit }, { locale, name, commitSeo = true }) {
  locale = locale || state.defaultLocale
  if (!name) return null
  const seos = await stelace.entries.list({ collection: 'seo', locale, name })
  if (seos[0] && seos[0].fields && commitSeo) await commit('content/setMetadata', seos[0].fields, { root: true })
  return seos[0]
}
