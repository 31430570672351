import { find, get, set, merge } from 'lodash'
import { cdnImg } from 'hc-core/mixins/aws.js'

export const DEFAULT_SEO = merge(
  // Here are the common parts, always used as default
  {
    fbAppId: '2165029433804883',
    type: 'website',
    ldJson: {
      type: 'application/ld+json',
      innerHTML: '{"@context":"http://schema.org","@type":"WebPage"}'
    }
  },
  // Here is the platform specific variations, merged to to above defaults
  get({
    WEB: {
      title: 'HappyCab x VDC - La plateforme de recrutement de l\'expertise comptable',
      description: 'La plateforme où les cabinets, les entreprises et les candidats les plus cools se rencontrent',
      url: 'https://happycab.fr',
      image: 'platform/branding/default-img-hc.jpg',
    },
    VDC: {
      title: 'VieDeComptable',
      description: 'VDC - La plus grande communauté de comptables de France',
      url: 'https://viedecomptable.fr',
      image: 'platform/branding/default-img-vdc.png'
    }
  }, process.env.HC_PLATFORM || 'WEB')
)

export async function setSeo ({ store, currentRoute, redirect }) {
  // processedSEO has the same properties as listed in DEFAULT_SEO, no more
  const processedSEO = {}

  // Here the whole process of specific page, writing in processedSEO
  const companyRoutes = ['companyPage', 'companyOffers', 'companyOffer', 'companyLocations']
  try {
    if (currentRoute.meta.dynamicSEO) {
      if (companyRoutes.includes(currentRoute.name)) { // CompanyLayout routes
        const company = await store.dispatch('search/companyBySlug', { slug: currentRoute.params.slug })
        if (!company) {
          redirect({ name: 'notFound' })
        } else {
          let title = company.name + ' - HappyCab'
          if (currentRoute.name === 'companyOffer') {
            title = company.name + ' - Offre HappyCab'
            const offer = find(company.offers, (o) => o.customAttributes.slug === currentRoute.params.assetSlug)
            if (!offer) {
              redirect({ name: 'companyOffers', params: { slug: currentRoute.params.slug } })
            }
            if (offer && offer.name) title = offer.name + ' - ' + company.name
          }
          set(processedSEO, 'title', title)
          set(processedSEO, 'description', company.description)
          set(processedSEO, 'image', get(company, 'metadata._files.logo', undefined))
        }
      } else if (currentRoute.name === 'article') { // Blog article routes
        const article = await store.dispatch('content/fetchArticle', { locale: 'fr', slug: currentRoute.params.slug })

        if (article) {
          // Google Structured data for article display on search results
          // https://developers.google.com/search/docs/appearance/structured-data/article
          set(processedSEO, 'title', `${process.env.HC_PLATFORM === 'VDC' ? 'VDC' : 'Happy Média'} - ${article.fields.title}`)
          set(processedSEO, 'description', article.fields.subtitle)
          set(processedSEO, 'type', 'article')
          set(processedSEO, 'image', article.fields.cover)
          set(processedSEO, 'ldJson', {
            type: 'application/ld+json',
            innerHTML: JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'NewsArticle',
              headline: get(article, 'fields.title', ''),
              image: [cdnImg(get(article, 'fields.cover', 'platform/branding/default-img-hc.jpg'), { quality: 75 })],
              datePublished: new Date(article.publishedDate || article.updatedDate).toISOString(),
              dateModified: new Date(article.updatedDate).toISOString(),
              author: get(article, 'fields.author', false) ? [{ '@type': 'Person', name: get(article, 'fields.author.name', 'HappyCab') }] : undefined
            })
          })
        }
      } else if (currentRoute.name === 'knowledgeTools') { // Tools routes
        const currentEntry = store.getters['content/currentEntry'] ?? await store.dispatch('content/getEntryByName', { name: get(currentRoute, 'params.name', null) })
        if (currentEntry) {
          set(processedSEO, 'title', get(currentEntry, 'fields.label', get(currentEntry, 'name', 'Un outil')) + ' - HappyCab')
          set(processedSEO, 'description', get(currentEntry, 'fields.description', 'Un outil sur Happycab'))
          set(processedSEO, 'image', cdnImg(`assets/attributes/tools/${currentEntry.name}.png`))
        }
      }
    } else if (currentRoute.meta.staticSEO && currentRoute.meta.fields) {
      set(processedSEO, 'title', get(currentRoute, 'meta.fields.titletag', undefined))
      set(processedSEO, 'description', get(currentRoute, 'meta.fields.description', undefined))
      set(processedSEO, 'image', get(currentRoute, 'meta.fields.image', undefined))
    } else {
      const dbSEO = await store.dispatch('content/fetchSeo', {
        locale: 'fr',
        name: `${process.env.HC_PLATFORM.toLowerCase()}_${get(currentRoute, 'meta.seoEntryName', get(currentRoute, 'name'))}`
      })
      set(processedSEO, 'title', get(dbSEO, 'fields.title', undefined))
      set(processedSEO, 'description', get(dbSEO, 'fields.description', undefined))
      set(processedSEO, 'image', get(dbSEO, 'fields.image', undefined))
    }
  } catch (e) {
    // handle error
    await store.dispatch('common/sendCustomEvent', { type: 'seo_fetch_error', objectId: currentRoute.name, emitterId: process.env.HC_PLATFORM, metadata: { currentRoute } })
  }

  // Just some final BUT COMMON computation, not doable in DEFAULT_SEO directly
  const robots = process.env.DEV ? 'noindex' : 'index'
  const imgUrl = cdnImg(get(processedSEO, 'image', DEFAULT_SEO.image), { quality: 75 })
  const title = get(processedSEO, 'title', DEFAULT_SEO.title)
  const description = get(processedSEO, 'description', DEFAULT_SEO.description)

  // Creating object passed to useMeta
  const finalSEO = {
    title,
    meta: {
      robots: { name: 'robots', content: robots },
      description: { name: 'description', content: description },
      image: { name: 'image', content: imgUrl },
      ogTitle: { property: 'og:title', content: title },
      ogDescription: { property: 'og:description', content: description },
      ogImage: { property: 'og:image', content: imgUrl },
      ogImageAlt: { property: 'og:image:alt', content: imgUrl },
      url: { property: 'og:url', content: process.env.HC_BASE_URL + currentRoute.path },
      type: { property: 'og:type', content: get(processedSEO, 'type', DEFAULT_SEO.type) },
      fbAppId: { property: 'fb:app_id', content: DEFAULT_SEO.fbAppId },
      twitterImage: { property: 'twitter:image', content: imgUrl },
      twitterDescription: { property: 'twitter:description', content: description },
    },
    script: {
      ldJson: get(processedSEO, 'ldJson', DEFAULT_SEO.ldJson)
    }
  }
  await store.commit('content/setSeo', finalSEO)
}
