import routes from './routes'
import { route } from 'quasar/wrappers'
import { setSeo } from 'hc-core/composables/seo'
import { base64Decoder } from 'hc-core/composables/routing'
import { analyticsSlugExtractor } from 'hc-core/composables/logger'
import { createRouter, createMemoryHistory, createWebHistory, createWebHashHistory } from 'vue-router'

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

export default route(async function ({ store }) {
  const createHistory = process.env.SERVER
    ? createMemoryHistory
    : (process.env.VUE_ROUTER_MODE === 'history' ? createWebHistory : createWebHashHistory)

  const Router = createRouter({
    scrollBehavior: () => ({ left: 0, top: 0 }),
    routes,

    // Leave this as is and make changes in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    history: createHistory(process.env.MODE === 'ssr' ? undefined : process.env.VUE_ROUTER_BASE)
  })

  // Extracted SEO call to allow SEO earlier on server
  Router.beforeResolve(async (to, from, next) => {
    if (to) await setSeo({ store, currentRoute: to, redirect: Router.push })
    if (to.query.redirect) next(base64Decoder(to.query.redirect))
    else next()
  })

  Router.beforeEach(async (to, from, next) => {
    try {
      await analyticsSlugExtractor({ store, to })
      if (to.query.redirect) next(base64Decoder(to.query.redirect))
      else next()
    } catch (e) {
      Router.replace({ name: 'home' })
    }
  })

  return Router
})
