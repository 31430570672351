export default function () {
  return {
    i18nContent: {},
    article: null,
    pages: {},
    relatedArticles: {},
    blogPagination: null,
    blogCategory: undefined,
    blogPosts: [],
    locale: 'fr',
    subscriptions: [],
    features: [],
    postsLiked: [],
    seo: {
      meta: {
        title: 'VieDeComptable',
        description: 'VDC - La plus grande communauté de comptables de France',
        url: 'https://viedecomptable.fr',
        type: 'website',
        fbAppId: '2165029433804883',
        image: 'platform/branding/default-img-vdc.png'
      },
      script: {
        ldJson: {
          type: 'application/ld+json',
          innerHTML: '{"@context":"http://schema.org","@type":"WebPage"}'
        }
      }
    },
    analytics: {}
  }
}
