// Mixin specific for this project
// For always loaded mixins, refer to mixins/global

import { mapState } from 'vuex'

export default {
  async preFetch ({ store }) {
    await store.dispatch('common/fetchConfig', { forceRefresh: true }, { root: true })
  },
  computed: {
    ...mapState(['common', 'content']),
    currentNaturalUser () { return null },
  }
}
