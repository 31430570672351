import { get, uniqBy, set } from 'lodash'
import { mergeEntries } from 'hc-core/composables/content'

export async function seti18nContent (state, { content }) {
  state.i18nContent = mergeEntries({ localEntries: content })
}

export function setLocale (state, { locale }) {
  state.locale = locale
}

export function setBlogPosts (state, posts) {
  state.blogPosts = posts
}

export function setPages (state, pages) {
  state.pages = pages
}

export function setBlogList (state, blogList) {
  let uniqueBlogList = state.blogList
  if (state.blogPagination) uniqueBlogList = uniqueBlogList.concat(blogList)
  else uniqueBlogList = blogList
  state.blogList = uniqBy(uniqueBlogList, 'id')
}

export function setArticle (state, { article }) {
  state.article = article
}

export function setBlogPagination (state, pagination) {
  state.blogPagination = pagination
}

export function setBlogCategory (state, category) {
  state.blogCategory = category
}

export function setSubscriptionsList (state, { subscriptions, features }) {
  state.subscriptions = subscriptions
  state.features = features
}

export function setRelatedArticle (state, { article }) {
  state.relatedArticles[article.name] = article
}

export function setPostLiked (state, { postId }) {
  state.postsLiked.push(postId)
}

export function setMetadata (state, fields) {
  const metaKeys = ['title', 'image', 'description', 'type']
  const scriptKeys = ['ldJson']
  for (const key in fields) {
    if (metaKeys.includes(key)) state.seo.meta[key] = fields[key]
    else if (scriptKeys.includes(key)) state.seo.script[key] = fields[key]
  }
}

export function setSeo (state, seo) {
  state.seo = seo
}

export function setAnalyticsSlug (state, slug) {
  if (slug) {
    const ANALYTICS_ROOT_PATH = 'analytics'
    set(state, `${ANALYTICS_ROOT_PATH}.${slug}`, parseInt(get(state, `${ANALYTICS_ROOT_PATH}.${slug}`, 0)) + parseInt(1))
  }
}

// WIP : flex way
export function setEntries (state, { key, arr = [] }) {
  set(state, key, arr)
}
